/*******************************
         Site Overrides
*******************************/

.ui.primary.basic.button {
  box-shadow: 0 0 0 @basicBorderSize @borderColor inset;

  &:hover, &:focus, &:active {
    background-color: @subtleTransparentBlack;
    box-shadow: 0 0 0 @basicBorderSize @borderColor inset;
  }

  &.rounded-icon {
    position: relative;
    height: 50px;
    border-radius: 50px;
    padding-right: 35px;
    text-align: left;

    i {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.ui.very.basic.button {
  background: none;
  box-shadow: none;
  color: @grey;
  display: inline-flex;
  align-items: center;
  font-weight: normal;

  &:hover, &:focus, &:active {
    background: none;
    box-shadow: none;
    color: @greyHover;
  }

  &.unclickable, &.disabled {
    cursor: default;

    &:hover, &:focus, &:active {
      color: @grey;
    }
  }

  &.dark {
    color: @white;

    &:hover, &:focus, &:active {
      color: @white;
    }
  }
}
