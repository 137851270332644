/*******************************
         Site Overrides
*******************************/

.ui.sortable.table {
  thead {
    tr {
      th {
        border-width: 2px !important;

        &:not(.sortable) {
          cursor: default;
        }

        &.sortable:not(.sorted) {
          &:after {
            content: "\f0dc";
            display: inline-block;
            color: @grey;
            opacity: .7;
            font-size: 13px;
          }
        }

        &.sorted {
          border-bottom: 2px solid @primaryColor;

          &:after {
            color: @primaryColor;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.ui.unstackable.table {
  tr:not(:first-child) {
    td, th {
      border-top: 1px solid rgba(34,36,38,.1);
    }
  }
}


