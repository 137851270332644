@import './folio.variables.less';
@import './folio.layouts.less';

html, body {
  background: var(--folio--color--grey-background);
}

body#folio {
  height: auto;
  min-height: 100vh;
  background: #fff;
}

a {
  cursor: pointer;
}

.hidden-opacity {
  opacity: 0 !important;
}
