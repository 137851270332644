/*******************************
         Site Overrides
*******************************/

.ui.breadcrumb {
  i {
    color: var(--folio--color--grey-text);
  }

  .section {
    font-weight: bold;
  }

  .active {
    color: var(--folio--color--grey-text);
  }
}
