/*******************************
         Site Overrides
*******************************/

.ui.basic.labels {
  .ui.label {
    border: 1px solid @borderColor !important;
    font-weight: normal;
    display: inline-flex;

    span {
      margin: 0 5px 0 0;
      font-weight: bold;
    }

    i.icon {
      margin: 0 0 0 10px;
      font-weight: bold;
      cursor: pointer;

      &.left-side {
        margin: 0 3px 0 0;
      }
    }

    &:hover, &:focus, &:active {
      background-color: @subtleTransparentBlack;
    }
  }
}

.ui.tertiary.label {
  font-weight: bold;
  text-transform: uppercase;
  padding: 0;
  background: transparent !important;
  margin: 0 .5em 0 0;

  &:last-child {
    margin: 0;
  }

  &.grey {
    color: @grey !important;
  }

  &.red {
    color: @red !important;
  }
}

.ui.quaternary.label {
  font-size: inherit;
  padding: 0;
  background: transparent !important;

  &.grey {
    color: @grey !important;
  }

  &.red {
    color: @red !important;
  }
}
