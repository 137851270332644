/*******************************
         Site Overrides
*******************************/


.ui.modals {
  z-index: 5000;
}

.ui.modal {
  >.header {
    text-align: center;
    font-size: 18px !important;

    span {
      .line-clamp(2);
    }
  }

  >.actions {
    display: flex;
    flex-grow: 1;

    a, .ui.button {
      flex-grow: 1;
      flex-basis: 100%;
      cursor: pointer;
    }

    .ui.button {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &.link {
      padding: 0 !important;

      a {
        padding: 1em 1.25em;
        display: inline-block;
        font-size: @relative16px;
      }
    }
  }
}
