/*******************************
         Site Overrides
*******************************/

.ui.form {

  .field {
    &:last-child {
      margin-bottom: 1em;
    }

    &.error {
      .ui.basic.labels .red.label,
      .ui.ui.ui.basic.red.label {
        color: #DB2828 !important;
      }
    }
  }
}
