/*******************************
        User Overrides
*******************************/

.ui.accordion .title .angle.down.icon {
  transition: .1s ease;
  position: absolute;
  right: 5px;
  font-size: 22px;
  color: @grey;
}

.ui.accordion .active.title .angle.down.icon,
.ui.accordion .accordion .active.title .angle.dow.icon {
  transform: rotate(180deg);
}

.ui.accordion {
  .title:not(.ui) {
    font-weight: bold;
  }

  &.large {
    .title:not(.ui) {
      font-size: @relative16px;
    }
  }
}
