/*******************************
        User Overrides
*******************************/

.ui.selection.dropdown {
  .ui.label {
    background-color: @teal;
    color: @white;
  }
}
