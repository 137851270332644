/*******************************
         Site Overrides
*******************************/

.ui.input.icon {
  i.icon {
    color: @grey;
  }

  > input:focus~i.icon {
    color: @primaryColor;
  }
}

.ui.tooltip.input {
  display: grid !important;
  grid-template-columns: 1fr 50px !important;

  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: none !important;
  }

  .info.icon {
    cursor: pointer;
    font-size: 24px;
    padding: 0 10px;
    border: 1px solid @borderColor;
    border-radius: 0 4px 4px 0;
    display: flex;
    place-items: center;
    width: 100%;
    height: 100%;

    &:hover {
      background: @subtleTransparentBlack;
    }
  }
}
