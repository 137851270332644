/**
  TODO - update with the custom colors used in Semantic variables
       - allows for using "Semantic" style variables in Angular components
 */
:root {
  --folio--color--white: #FFF;
  --folio--color--off-white: #F4F8F8;

  // primary aka Fomantic "teal"
  --folio--color--primary: #007979;
  --folio--color--dark-primary: #004646;
  --folio--color--light-primary: #DDF2F2;
  --folio--color--focused-form-border-color: #83C3C3;

  --folio--color--color: #FBBD08;

  --folio--color--black-presentation-background: #000;
  --folio--color--grey-presentation-background: #1b1c1d;

  --folio--color--grey-background: #f4f8f8;
  --folio--color--grey-border: #d2dce5;
  --folio--color--grey-icon: #97a9b3;
  --folio--color--grey-text: #6E7881;
  --folio--color--grey-text-hover: #3f454a;

  --folio--color--text: rgba(0, 0, 0, 0.87);
  --folio--color--muted-text: rgba(0, 0, 0, 0.7);

  --folio--color--transparent-black: rgba(0, 10, 70, 0.05);

  --folio--color--inverted-border: rgba(255, 255, 255, .2);

  --folio--width--main-menu: 200px;
  --folio--width--library-filters: 360px;

  --folio--height--header: 50px;
  --folio--height--mobile-navigation: 53px;
  --folio--height--folio-search-menu: 88px;
  --folio--height--folio-search-bar: 43px;
  --folio--height--library-sort-menu: 67px;
  --folio--height--library-filter-row: 50px;
  --folio--height--library-filter-buttons: 94px;
  --folio--height--bulk-select-menu: 67px;
  --folio--height--profile-link: 50px;
  --folio--height--viewer-top-menu: 50px;
  --folio--height--viewer-bottom-menu: 50px;

  --folio--z-index--folio-search-menu: 100;
  --folio--z-index--card-checkbox: 100;
  --folio--z-index--viewer-menus: 1000;
  --folio--z-index--mobile-nav-panel: 1500;
  --folio--z-index--mobile-nav-bar: 1501;
  --folio--z-index--library-filters-underlay: 1900;
  --folio--z-index--library-filters: 2000;
  --folio--z-index--library-filters-dropdown: 2100;
  --folio--z-index--library-filters-active-dropdown: 2300;
  --folio--z-index--library-filters-footer-buttons: 2200;
  // modals z-index is 5000. see modal.overrides
  // tooltip z-index is 6000. see popup.overrides

  --folio--border-radius: 0.285714rem; // 4px
}
