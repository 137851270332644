// layout styles shared by the Home, Profile and Share History pages

.folio--content-page-wrap {
  padding: 2em;
  display: flex;
  flex-direction: column;
  height: 100%;

  // tablet and mobile
  @media (max-width: 992px) {
    padding-bottom: 73px;
  }

  // header
  // ----------------------------------------
  .folio--content-page-header {
    padding: 0 0 14px;

    @media (min-width: 769px) {
      display: grid;
      grid-template-columns: 1fr auto;
    }

    &:not(:first-of-type) {
      margin-top: 4em;
    }

    .folio--content-page-header--left {
      h2 {
        font-size: 28px;
        padding: 0 0 7px;
        margin: 0;

        &.no-bottom-padding {
          padding-bottom: 0;
        }
      }
    }

    .folio--content-page-header--right {
      @media (max-width: 768px) {
        padding-top: 1em;
      }

      @media (min-width: 769px) {
        padding-left: 2em;
      }
    }
  }

  // content
  // ----------------------------------------
  .folio--content-page-content {

  }

  // footer
  // ----------------------------------------
  .folio--content-page-footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 20px;

    img {
      width: 40px;
      margin-bottom: 20px;
    }

    p {
      color: rgba(0,0,0,.6);
      font-size: 13px;
      line-height: 20px;
    }

    a {
      text-decoration: underline;
      color: inherit;
    }
  }
}



.folio--scrollable-table-wrap-outer {
  width: calc(100vw - (4em + var(--folio--width--main-menu)));
  height: 500px;
  position: relative;
  overflow: hidden;

  .folio--scrollable-table-wrap-inner {
    overflow: scroll;
    height: 100%;
    width: 100%;

    table {
      td, th {
        white-space: nowrap;
      }
    }
  }

  &:before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
    background: linear-gradient(to bottom, transparent, rgba(0,0,0,.25));
  }

  &:after {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    top: 0;
    width: 5px;
    background: linear-gradient(to right, transparent, rgba(0,0,0,.25));
  }
}
