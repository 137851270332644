/*******************************
     User Global Variables
*******************************/

@imagePath : '../../default/assets/images';
@fontPath  : '../../default/assets/fonts';

/*
  TODO - port some of these to Angular side so we can use them there
 */
@primaryColor: @teal;
@lightPrimaryColor: @lightTeal;

@h4 : unit((16 / 14), rem);

@inputVerticalPadding: @relative15px;
@inputPlaceholderColor: lighten(@inputColor, 55);
@focusedFormBorderColor: #83C3C3;
@focusedFormMutedBorderColor: @focusedFormBorderColor;

@linkColor: @primaryColor;
@linkHoverColor: @primaryColorHover;

@useCustomScrollbars: false;

@teal: #007979;
@grey: #6E7881;
@lightTeal: #DDF2F2;
@offWhite: #F4F8F8;

@mutedTextColor: rgba(0, 0, 0, 0.7);

@borderColor: rgba(0, 10, 70, 0.15);
@selectedBorderColor: rgba(0, 10, 70, 0.35);
@solidBorderColor: #D2DCE5;

@primaryColorHover: darken(@primaryColor, 5%);
@tealHover: darken(@teal, 5%);
@greyHover: darken(@grey, 20%);

@subtleTransparentBlack: rgba(0, 10, 70, 0.02);
@transparentBlack: rgba(0, 10, 70, 0.05);

@positiveBackgroundColor: lighten(@green, 55%);
@positiveBorderColor: @green;
@positiveHeaderColor: darken(@green, 30%);
@positiveTextColor: @positiveHeaderColor;

@negativeBackgroundColor: lighten(@red, 47%);
@negativeBorderColor: @red;
@negativeHeaderColor: darken(@red, 30%);
@negativeTextColor: @negativeHeaderColor;

@infoBackgroundColor: lighten(@blue, 50%);
@infoBorderColor: @blue;
@infoHeaderColor: darken(@blue, 25%);
@infoTextColor: @infoHeaderColor;

@warningBorderColor: @yellow;
@warningBackgroundColor: lighten(@yellow, 48%);
@warningHeaderColor: darken(@yellow, 35%);
@warningTextColor: @warningHeaderColor;

.line-clamp(@num) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: @num;
}

/*-------------------
  Emotive Message Colors
--------------------*/

/* Generic */
@genericMessageColor            : #adaeaf;
@genericMessageBackgroundColor  : #f8f8f9;
@genericMessageTextColor        : #363636;
@genericMessageLinkHoverColor   : @black;


/* Positive */
@positiveMessageColor           : @green;
@positiveMessageBackgroundColor : lighten(@green, 55%);
@positiveMessageBorderColor     : @green;
@positiveMessageHeaderColor     : darken(@green, 30%);
@positiveMessageTextColor       : darken(@green, 30%);
@positiveMessageLinkHoverColor  : darken(@green, 40%);

/* Negative */
@negativeMessageColor           : @red;
@negativeMessageBackgroundColor : lighten(@red, 47%);
@negativeMessageBorderColor     : @red;
@negativeMessageHeaderColor     : darken(@red, 30%);
@negativeMessageTextColor       : darken(@red, 30%);
@negativeMessageLinkHoverColor  : darken(@red, 45%);

/* Info */
@infoMessageColor              : @blue;
@infoMessageBackgroundColor    : lighten(@blue, 50%);
@infoMessageBorderColor        : @blue;
@infoMessageHeaderColor        : darken(@blue, 25%);
@infoMessageTextColor          : darken(@blue, 25%);
@infoMessageLinkHoverColor     : darken(@blue, 40%);

/* Warning */
@warningMessageColor           : @yellow;
@warningMessageBackgroundColor : lighten(@yellow, 48%);
@warningMessageBorderColor     : @yellow;
@warningMessageHeaderColor     : darken(@yellow, 35%);
@warningMessageTextColor       : darken(@yellow, 35%);
@warningMessageLinkHoverColor  : darken(@yellow, 45%);
