/*******************************
         Site Overrides
*******************************/

.ui.menu {
  &.top.page.attached, &.bottom.page.attached {
    border-radius: 0;
    border: 0;

    >.item:first-child {
      border-radius: 0;
    }
  }

  &.top.page.attached {
    border-bottom: 1px solid @solidBorderColor;
  }

  &.bottom.page.attached {
    border-top: 1px solid @solidBorderColor;
  }

  &.centered {
    justify-content: center;
  }
}

.ui.tabular.menu {
  .item {
    cursor: pointer;
    border-color: @solidBorderColor;
    border-top-width: 1px;
    margin-right: -1px;
    border-radius: @defaultBorderRadius @defaultBorderRadius 0 0;
    background: @offWhite;

    &:first-child {
      border-radius: inherit !important;
    }

    &:hover:not(.active) {
      background: lighten(@offWhite, 2%);
    }
  }
}
