/*******************************
        Site Overrides
*******************************/

.ui.message {
  box-shadow: none !important;
  border: 1px solid !important;
  padding: 0 !important;
  margin: 0 auto !important;

  &.icon {
    > .icon:not(.close) {
      align-self: stretch !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      width: 40px !important;
      height: auto !important;
    }
  }

  .header {
    font-size: 15px !important;
  }

  .content {
    padding: 20px !important;
    text-align: left !important;
  }

  p, ul li {
    font-size: 14px !important;
    opacity: 1 !important;
  }

  a {
    &:not(.button) {
      text-decoration: underline !important;
    }
  }

  .folio-color-message(@border-color, @background-color, @text-color, @link-hover-color) {
    border-color: @border-color !important;
    background: @background-color !important;
    color: @text-color !important;

    *:not(.button) {
      color: @text-color !important;
    }

    a:not(.button):hover {
      color: @link-hover-color !important;
    }

    &.icon {
      > .icon:not(.close) {
        background: @border-color !important;
        color: @background-color !important;
      }
    }
  }

  &.generic {
    .folio-color-message(@genericMessageColor, @genericMessageBackgroundColor, @genericMessageTextColor, @genericMessageLinkHoverColor)
  }

  &.negative {
    .folio-color-message(@negativeMessageBorderColor, @negativeMessageBackgroundColor, @negativeMessageTextColor, @negativeMessageLinkHoverColor);
  }

  &.warning {
    .folio-color-message(@warningMessageBorderColor, @warningMessageBackgroundColor, @warningMessageTextColor, @warningMessageLinkHoverColor);
  }

  &.info {
    .folio-color-message(@infoMessageBorderColor, @infoMessageBackgroundColor, @infoMessageTextColor, @infoMessageLinkHoverColor);
  }

  &.positive {
    .folio-color-message(@positiveMessageBorderColor, @positiveMessageBackgroundColor, @positiveMessageTextColor, @positiveMessageLinkHoverColor);
  }
}
